import React, { useEffect, useState } from 'react';
import './reception-block.css';
import { useSnackbar } from '../notification/index';


function ReseptionServicesBlock({ data, id, name, price, parentId, onClick, i, numbers, description, isSearching = false, isCommerce }) {

  const { enqueueSnackbar } = useSnackbar();

  let config = window.config;

  if (!numbers) numbers = i; else numbers = numbers + '.' + i;

  let subServices = data.filter((el) => { return el.parentId == id; });

  const [activeServices, setActiveServices] = useState(isSearching)

  useEffect(() => {
    setActiveServices(isSearching);
  }, [isSearching])
  
  function OpenServices() {
    setActiveServices(!activeServices);
  }

  return (
    <div className="reception-services__content">
      <div className="reception-services__block-before" onClick={subServices.length ? OpenServices : ()=> onClick(id, name, price, description)}><div className="reception-services__block">
        <span id={id}>
          {config.show_services_numbers ? `${numbers}. ${name}` : `${name}`}
        </span>
        {subServices.length ? (<span className="icon icon_s icon_background-auto icon_services-accardion" style={activeServices ? {} : { transform: 'rotate(180deg)' }} ></span>) : config.show_services_price && config.currency.length != 0 && price && isCommerce ? (<div className='reception-services__price'>{price.toLocaleString()} {config.currency}</div>) : (<></>)}
      </div>
      </div>
      {subServices.length ? (<>
        <div className={"reception-services__sub-services" + `${activeServices == true ? " active" : ""}`}>
          {subServices.map((el, i) => <React.Fragment key={i + 10}>
          <ReseptionServicesBlock numbers={numbers} key={el} i={i + 1} data={data} id={el.id} name={el.name} price={el.price} isCommerce={isCommerce} description={el.description} isSearching={isSearching} parentId={el.parentId} onClick={onClick} />
          </React.Fragment>)}
        </div>
      </>) : (<></>)}
    </div>
  )

}

export default ReseptionServicesBlock;

/*

*/