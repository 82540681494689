import React, { useState, useEffect } from 'react';
import Buttons from '../components/buttons/buttons';


function SelectTypeReception({ onClickg }) {
    return (<>
        <div className='select-type-reception'>
            <div>
                <Buttons id={"select-doctor"} type="button-branch" text={'Запись по доктору'} icon="icon icon_s icon_background-auto icon_step-3" onClick={onClickg} />
                <Buttons id={"select-service"} type="button-branch" text={'Запись по услуге'} icon="icon icon_s icon_background-auto icon_step-2" onClick={onClickg} />
            </div>
        </div>
    </>)
}

export default SelectTypeReception;