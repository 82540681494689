import React from 'react';
import './filter.css'

function Filter({children, active}) {

  return (
    <div className={"filter"+`${active == true ? " active-mobile" : ""}`}>
        <div className="filter__container">
          <div className="filter__icon">
            <span className="icon icon_m icon_background-auto icon_filter-white"></span>
          </div>
          <div className="filter__content">
            {children}
          </div>
        </div>
    </div>
  )

}

export default Filter;
