import React from 'react';
import './styles/preloader.css';


function Preloader({ }) {

    return (
        <div className="preloader">
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
    )

}

export default Preloader;