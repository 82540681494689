import React, { useState, useEffect } from 'react';
import './reception-block.css';
import ReseptionServicesBlock from './reception-services-block.jsx';
import Search from '../components/search/Search';


function ReceptionServices({ onClick, data, onClickType, activeType, idType, typeService }) {

  let services;

  const [servicesData, setServicesData] = useState(data);
  const [filtered, setFiltered] = useState(false);
  const [isSearched, setIsSearched] = useState(false);

  let filter_data = data.map((s) => {
    let children_count = data.reduce((count, x) => (x.parentId == s.id ? count + 1 : count), 0);
    if (children_count > 0) {
      s.isParent = true;
    }
    return s;
  });

  useEffect(() => {
    setServicesData(data)
  }, [data])

  function searchResult(filter_array) { 
    
    if(filter_array == false){
      setIsSearched(false);
      setServicesData(data);
      return setFiltered(filter_array);
    }
    setIsSearched(true);


    for (let index = 0; index < filter_array.length; index++) {
      
      if(filter_array[index].parentId){
        if(filter_array.filter(i => i.id == filter_array[index].parentId).length == 0){
          filter_array.push(data.find((n) => n.id == filter_array[index].parentId));
        }
      }
    }

    for (let i = 0; i < filter_array.length; i++) {
      if(filter_array[i].isParent){
        if(!filter_array.some(elem => elem.parentId == filter_array[i].id)){          
          for (let dataIndex = 0; dataIndex < data.length; dataIndex++) {
            if(data[dataIndex].parentId == filter_array[i].id){
              filter_array.push(data[dataIndex])
            }
          }
        }
      }
    }

    setServicesData(filter_array);
    let res = filter_array.filter((item) => item.parentId == null)
    setFiltered(res); 
  }

  if (filtered || Array.isArray(filtered)) {
    services = filtered;
  } else {
    services = servicesData.filter((el) => { return !el.parentId; });
  }

  return (
    <div className={"reception-services"}>
      <div className="reception-services__container">
        {typeService.length > 1 &&
          <div className="reception-services__type-service">
            <div className="reception-services__type-info">
              <div className="reception-services__type-title">
                <div className="reception-services__type-icon">
                  <span className="icon icon_m icon_background-auto icon_type-reception"></span>
                </div>
                <div className="reception-services__type-text">
                  <div>Выберите тип услуги</div>
                  <div>На основании которого будет проходить
                    медицинское обследование</div>
                </div>
              </div>
            </div>
            <div className="reception-services__type-content">
              {typeService !== 'error' &&
                <>{typeService.map((type, keyId) =>
                  <div id={type.key} data-id={keyId} key={keyId + 10} className={"reception-services__type-block" + `${idType == type.key && activeType == true && data ? " active" : ""}`} onClick={onClickType}>{type.value}</div>
                )}</>
              }
            </div>
          </div>
        }
        {typeService &&
          typeService.map((el, i) =>
            <div key={i} className={"reception-services__message" + `${idType == el.key && el.comment != '' ? " active" : ""}`} style={{ marginBottom: '24px', marginTop: '-24px', textAlign: 'center' }}>Примечание к услуге: {el.comment}</div>
          )
        }
        <div className="reception-services__content">
          <div style={{ marginBottom: '24px' }}><Search data={filter_data} field="name" callback={searchResult} /></div>
          {data !== 'error' && services.length > 0 ? (
            <>{services.map((el, i) => <React.Fragment key={i + 10}>
              <ReseptionServicesBlock i={i + 1} data={servicesData} id={el.id} name={el.name} isSearching={isSearched} price={el.price} isCommerce={idType == 0} description={el.description} parentId={el.parentId} onClick={onClick} />
            </React.Fragment>
            )}</>) : (<>
              {filtered && filtered.length == 0 ? (
                <div>По данному запросу услуги не найдены</div>
              ) : (
                <div>Услуги по данному типу отсутствуют</div>
              )}
            </>)}
        </div>
      </div>
    </div>
  )

}

export default ReceptionServices;