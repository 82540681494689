import React, { useEffect, useState } from 'react';

function ReceptionBlock({ nextTabType, children, data, onClick, activeDone, branchesCount, currentTab, NextClick, PrevClick, severRes, typeReception }) {
    // филиалы с сервера
    let config = window.config;
    let i = 1;
    let m_i = 1;

    let width = window.screen.width;
    let IconTabs = 'icon_s';

    const [widthScreen, setWithScreen] = useState(width);

    window.onresize = function () {
        setWithScreen(window.screen.width);
    };

    if (widthScreen <= 1400) {
        IconTabs = 'icon_s';
    } else {
        IconTabs = 'icon_s';
    }

    // Проверка шагов для мобильных
    let prevTabActive = true;
    let nextTabActive = true;

    if (currentTab == 'branch')
        if (data.clinicBranchId) nextTabActive = true; else nextTabActive = false;

    if (currentTab == 'service' && data.serviceId) {
        nextTabActive = true;
    } else {
        nextTabActive = false;
    }

    if (currentTab == 'doctor')
        if (data.doctorSpecializationId) nextTabActive = true; else nextTabActive = false;

    if (currentTab == 'success') nextTabActive = false;

    if (currentTab == 'finish') { prevTabActive = false; nextTabActive = false; }

    if(currentTab == 'selectType' && data.isOneBranch) { 
        prevTabActive = false;
    }

    let prevHidden = false;

    if (currentTab == 'branch' ||
        (currentTab == 'service' && branchesCount == 1) ||
        (document.location.search != '' && currentTab == 'services' && config.selectService && branchesCount == 1) ||
        (document.location.search != '' && currentTab == 'doctor' && !config.selectService && branchesCount == 1)) {

        prevHidden = true;
    }

    return (
        <div className="reception__container">
            <div className="reception__tab">
                <div className={"reception__tab-block reception_logo" + `${!config.selectService ? " reception_tab-block-5" : ""}`} ><a href={config.site_url} target={"_bank"}><img src={config.logo} alt="" /></a></div>

                <div className={"reception__tab-main"}>
                    {branchesCount != 1 &&
                        <div className={"reception__tab-block reception_step active" + `${currentTab == "branch" ? " current-tab" : ""}`} id="branch" onClick={!activeDone ? onClick : () => { }}>
                            <div className="reception__tab-icon">
                                <span className={"icon " + (IconTabs) + " icon_background-auto icon_left icon_step-1"}></span>
                            </div>
                            <div className="reception__tab-content">
                                <div className="reception__tab-title">
                                    Шаг {i++}
                                </div>
                                <div className="reception__tab-subtitle">
                                    Выбор филиала
                                </div>
                            </div>
                        </div>
                    }

                    {config.selectService == true && document.location.search == '' &&
                        <div className={"reception__tab-block reception_step" + `${currentTab == "selectType" ? " current-tab" : ""}` + `${data.clinicBranchId || branchesCount == 1 ? " active" : ""}`} id="selectType" onClick={data.clinicBranchId && !activeDone ? onClick : () => { }} >
                            <div className="reception__tab-icon">
                                <span className={"icon " + (IconTabs) + " icon_background-auto icon_left icon_step-2"}></span>
                            </div>
                            <div className="reception__tab-content">
                                <div className="reception__tab-title">
                                    Шаг {i++}
                                </div>
                                <div className="reception__tab-subtitle">
                                    Выбор типа записи
                                </div>
                            </div>
                        </div>
                    }

                    <div className={"reception__tab-block reception_step" + `${currentTab == "services" || currentTab == "doctor" ? " current-tab" : ""}` + `${(typeReception && config.selectService) || (!config.selectService && data.clinicBranchId) ? " active" : ""}`} id={nextTabType} onClick={(typeReception && !activeDone && config.selectService) || (!config.selectService && data.clinicBranchId) ? onClick : () => { }} >
                        <div className="reception__tab-icon">
                            <span className={"icon " + (IconTabs) + " icon_background-auto icon_left icon_step-2"}></span>
                        </div>
                        <div className="reception__tab-content">
                            <div className="reception__tab-title">
                                Шаг {i++}
                            </div>
                            <div className="reception__tab-subtitle">
                                запись на прием
                            </div>
                        </div>
                    </div>
                    <div className={"reception__tab-block reception_step" + `${currentTab == "success" ? " current-tab" : ""}` + `${data.doctorSpecializationId ? " active" : ""}`} id="success" onClick={data.doctorSpecializationId && !activeDone ? onClick : () => { }}>
                        <div className="reception__tab-icon">
                            <span className={"icon " + (IconTabs) + " icon_background-auto icon_left icon_step-4"}></span>
                        </div>
                        <div className="reception__tab-content">
                            <div className="reception__tab-title">
                                Шаг {i++}
                            </div>
                            <div className="reception__tab-subtitle">
                                Проверка данных
                            </div>
                        </div>
                    </div>
                    <div className={"reception__tab-block reception_step" + `${currentTab == "finish" ? " current-tab" : ""}` + `${activeDone ? " active" : ""}`}>
                        <div className="reception__tab-icon">
                            <span className={"icon " + (IconTabs) + " icon_background-auto icon_left icon_step-5"}></span>
                        </div>
                        <div className="reception__tab-content">
                            <div className="reception__tab-title">
                                Готово
                            </div>
                            <div className="reception__tab-subtitle"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="reception__mobile">
                <div className="reception__mobile-container">
                    <div className={"reception__mobile-prev" + `${prevHidden ? " hidden" : ""}` + `${prevTabActive ? "" : " disabled"}`} onClick={prevTabActive ? PrevClick : () => { }}>
                        <span className="icon icon_m icon_background-auto icon_reception-prev"></span>
                    </div>
                    <div className="reception__mobile-content">
                        <div className="reception__mobile-title">Запись на приём</div>
                        <div className="reception__mobile-step">
                            {branchesCount != 1 && (<>
                                <span style={currentTab == "branch" ? { display: "block" } : { display: "none" }}>Шаг {m_i++} - Выбор филиала</span>
                            </>)}
                            {config.selectService && document.location.search == '' &&
                                <span style={currentTab == "selectType" ? { display: "block" } : { display: "none" }}>Шаг {m_i++} - Выбор типа записи</span>
                            }

                            {config.selectService == true && currentTab == "services" &&
                                <span style={currentTab == "services" ? { display: "block" } : { display: "none" }}>Шаг {m_i++} - Запись</span>
                            }
                            <span style={currentTab == "doctor" ? { display: "block" } : { display: "none" }}>Шаг {m_i++} - Запись</span>
                            <span style={currentTab == "success" ? { display: "block" } : { display: "none" }}> Шаг {m_i++} - Проверка данных</span>
                            <span style={currentTab == "finish" ? { display: "block" } : { display: "none" }}>Готово</span>
                        </div>
                    </div>
                    <div className={"reception__mobile-next" + `${currentTab == 'finish' ? " hidden" : ""}` + `${nextTabActive ? "" : " disabled"}`} onClick={nextTabActive ? NextClick : () => { }}>
                        <span className="icon icon_m icon_background-auto icon_reception-next"></span>
                    </div>
                </div>
            </div>
            <div className="reception__content">
                {children}
            </div>
        </div >
    )





}

export default ReceptionBlock;
