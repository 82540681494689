import moment from "moment";

export default class Period {

    get_nextWeek(period) {
        let start_date = period.start_date.add(1, "isoWeek"); 
        let end_date = period.end_date.add(1, "isoWeek");
        return {start_date: start_date, end_date: end_date};
    }

    get_prevWeek(period) {
        let start_date = period.start_date.subtract(1, "isoWeek"); 
        let end_date = period.end_date.subtract(1, "isoWeek");
        return {start_date: start_date, end_date: end_date};
    }

    get_nextMonth(period) {
    let start_date = period.end_date;
    let end_date = period.end_date;
    
    if (period.start_date.year() == period.end_date.year()) {

        if (period.start_date.month() == period.end_date.month()) {
        start_date.startOf("month").add(1,'month').startOf("isoWeek"); 
        end_date = start_date.clone().endOf("isoWeek");
        }

        if (period.start_date.month() < period.end_date.month()) {
        start_date = end_date.startOf("month").add(1,'month').startOf("isoWeek"); 
        end_date = start_date.clone().endOf("isoWeek");
        }

    } else {
        start_date = end_date.clone().endOf("month").add(1,'month').startOf("isoWeek"); 
        end_date = start_date.clone().endOf("isoWeek");
    }

    return {start_date: start_date, end_date: end_date};
    }

    get_prevMounth(period) {
    let start_date = period.end_date;
    let end_date = period.end_date;
    
    if (period.start_date.year() == period.end_date.year()) {

        if (period.start_date.month() == period.end_date.month()) {
        end_date.startOf("month").subtract(1,'month').endOf("isoWeek"); 
        start_date = end_date.clone().startOf("isoWeek");
        }

        if (period.start_date.month() < period.end_date.month()) {
        end_date = start_date.startOf("month").subtract(1,'month').endOf("isoWeek");
        start_date = end_date.clone().startOf("isoWeek");
        }

    } else {
        end_date.startOf("month").subtract(1,'month').endOf("isoWeek"); 
        start_date = end_date.clone().startOf("isoWeek");
    }

    return {start_date: start_date, end_date: end_date};
    }
    
}