import React, { useEffect, useState, useLayoutEffect } from 'react';
import MiniModal from '../components/mini modal/miniModal';

function ReceptionDoctorMobile({ days, data, active, prevWeek, nextWeek, prevMounth, nextMounth, onClick, DoctorModal, viewPhoto, messageActive, closeMessageSwipe, receptionData }) {

  const [selectedDay, setSelectedDay] = useState({
    date: new Date().toLocaleDateString(),
    date_utc: new Date().toISOString().slice(0, -13) + '00:00:00'
  });

  const [translate, setTranslate] = useState({
    days: 0,
    doctor: 0,
    change_day: 1
  });

  let step_translate = {
    days: window.innerWidth / 2 - 32,
    doctor: window.innerWidth / 2 - 48,
  };

  useLayoutEffect(() => {

    function updateSize() {
      step_translate = {
        days: window.innerWidth / 2 - 32,
        doctor: window.innerWidth / 2 - 48,
      };
      setTranslate(prevState => ({
        ...prevState,
        days: translate.days,
        doctor: translate.doctor,
        change_day: translate.change_day
      }));
    }
    window.addEventListener('resize', updateSize);

    updateSize();

    return () => window.removeEventListener('resize', updateSize);

  }, []);

  function checkEmptyAndActiveDays(day){
    let className = day.date.slice(0, 2) == selectedDay.date.slice(0, 2) ? 'active' : '';

    for (const doctor of data) {
      if(doctor.arrFreeTimes[day.date_utc]?.length > 0) {
        return (
          <div key={day.date_utc} onClick={() => selectDay(day)} className={className} > {day.date.slice(0, 2)} </div>
        )
      }
    }

    return (
      <div key={day.date_utc} onClick={() => selectDay(day)} className={'empty ' + className} > {day.date.slice(0, 2)} </div>
    )
  }

  function checkDayTimes(){
    for (const doctor of data) {
      if(doctor.arrFreeTimes[selectedDay.date_utc]?.length > 0) {
        return true;
      }
    }
    return false;
  }

  function selectDay(day){
    setSelectedDay(prevState => ({
      ...prevState,
      date: day.date,
      date_utc: day.date_utc
    }));
  }

  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [isModal, setModal] = useState(false);

  function viewDoctorInfo(index){   
    let specializations = data[index].specializations.map((e, i) => {
      return ' ' + e.name + (data[index].specializations.length > 1 && i != Number(data[index].specializations.length - 1) ? ',' : '');
    })
    setSelectedDoctor({...data[index], specializations, fullName: data[index].fullName || data[index].userName});
    setModal(true);
  }

  useEffect(() => {
    let isSelected = false;
    for (const day of days) {
      if(day.date == new Date().toLocaleDateString()) {
        setSelectedDay(prevState => ({
          ...prevState,
          date: day.date,
          date_utc: day.date_utc
        }));
        isSelected = true;
        break;
      }
    }

    if(!isSelected){
      setSelectedDay(prevState => ({
        ...prevState,
        date: days[0].date,
        date_utc: days[0].date_utc
      }));
    }

  }, [days])

  return (
    <div className={"reception-doctor-mobile"}>
      <MiniModal
        isVisible={isModal}
        title={selectedDoctor?.fullName}
        content={<p>{selectedDoctor?.specializations} <br/>{selectedDoctor?.description} </p>}
        imageSrc={selectedDoctor?.photoUrl}
        onClose={(e) => {e.stopPropagation();setModal(false)}}
      />
      
      <div className='reception-doctor-mobile__calendar'>
        <div className='reception-doctor-mobile__calendar-month'>
          <div className='reception-doctor-mobile__calendar-button' onClick={prevMounth}>&lt;</div>
          <div className='reception-doctor-mobile__calendar-month-name'>{new Date(selectedDay.date_utc).toLocaleString('default', {month: 'long'})}</div>
          <div className='reception-doctor-mobile__calendar-button' onClick={nextMounth}>&gt;</div>
        </div>
        <div className='reception-doctor-mobile__calendar-days'>
          <div className='reception-doctor-mobile__calendar-button' onClick={prevWeek}>&lt;</div>
          {days.map((day) =>
           checkEmptyAndActiveDays(day)
          )}
          <div className='reception-doctor-mobile__calendar-button' onClick={nextWeek}>&gt;</div>
        </div>
      </div>

      <div className="reception-doctor-mobile__content">
        {data.length && checkDayTimes() ? <>{data.map((doctor, i) => 
          {
            return doctor.arrFreeTimes[selectedDay.date_utc]?.length > 0 ?
            (<div key={i} className="reception-doctor-mobile__block">
            
              <div className="reception-doctor-mobile__doctor">
                
                <div className="reception-doctor-mobile__photo-doctor">
                  {doctor.photoUrl != null ?
                    (<img src={doctor.photoUrl} onClick={(e) => {e.stopPropagation(); viewDoctorInfo(i)}}  />) :
                    (<span className="icon icon_l icon_background-auto icon_profile-block" onClick={(e) => {e.stopPropagation(); viewDoctorInfo(i)}} ></span>)
                  }
                </div>

                <div className='reception-doctor-mobile__content-doctor'>
                  <div className='reception-doctor-mobile__name'>{doctor.userName}</div>
                  <div className='reception-doctor-mobile__specializations'>
                    <div className='reception-doctor-mobile__spec'>
                      { doctor.specializations.map((e, i) => {
                        return `${e.name} ${(doctor.specializations.length > 1 && i != Number(doctor.specializations.length - 1)) ?  ', ' : ''}`;
                      })}
                    </div>
                  </div>
                  <p className='reception-doctor-mobile__service'>{receptionData.serviceText} -  {receptionData.price}</p>
                </div>
              </div>
              {doctor.arrFreeTimes[selectedDay.date_utc].find((time) => time.isFreeDocTime == true) != undefined
              ?
              <div className='reception-doctor-mobile__time-container'>
                {doctor.arrFreeTimes[selectedDay.date_utc].map((time, i) => {
                  return <>
                    {time.isFreeDocTime && (
                      <div key={doctor.id + i} onClick={() => onClick(doctor, time)}>{(time.timeBegin.slice(0, -3)) + " - " + (time.timeEnd.slice(0, -3))}</div>
                    )}</>
                })}                                    
              </div>
              : <div style={{textAlign:'center', marginTop: '10px'}}>Нет записей на выбранный день</div>
              }

              




            </div>)
          : <></>
          }
          )}</> :
            <div style={{ textAlign: 'center', paddingTop: '24px' }}>
              Нет записей на выбранный день
            </div>
        }
      </div>


    </div>
  )

}

export default ReceptionDoctorMobile;
