import React, { useState, useEffect } from 'react';
import WebApi from '../helpers/WebApi';
import Session from '../helpers/Session.js';
import { useSnackbar } from '../notification/index';
import { extendMoment } from "moment-range";
import Header from '../components/Header.jsx';
import HeaderMobile from '../components/HeaderMobile.jsx';
import ReceptionBlock from './reception-block.jsx';
import ReceptionBranch from './ReceptionBranch.jsx';
import ReceptionServices from './ReceptionServices.jsx';
import ReceptionDoctor from './ReceptionDoctor.jsx';
import ReceptionSuccess from './ReceptionSuccess.jsx';
import ReceptionFinish from './ReceptionFinish.jsx';
import TopLoader from "react-top-loader";
import originalMoment from "moment";
import 'moment/locale/ru';
import './reception-block.css';
import SelectTypeReception from './selectTypeReception';
import Button from '../notification/components/Button';
import Buttons from '../components/buttons/buttons';
import ListLinkDoctors from './listLinkDoctors';

const moment = extendMoment(originalMoment);

function Reception() {

  const linkGet = document.location.search;
  const linkGetParams = new URLSearchParams(linkGet);
  const doctorId = linkGetParams.get("doctor");
  const serviceId = linkGetParams.get("service");
  const linkBranchId = linkGetParams.get("branch");

  let web_api = new WebApi;
  let config = window.config;

  const location = window.location;

  const { enqueueSnackbar } = useSnackbar();

  // Стилизация загрузки страницы до ответа с сервера
  const [loader, setLoader] = useState(true);
  const [receptionLoader, setReceptionLoader] = useState(false);

  // Переменная для сбора данных для отправки на сервер
  const [data, setData] = useState({});

  // Переменная для отображения текущего этапа записи на приём
  const [currentTab, setCurrentTab] = useState('branch');

  // Переменная с типами услуг 
  const [typeService, setTypeService] = useState([]);

  // Активный тип услуги
  const [activeType, setActiveType] = useState(false);

  // Переменные для передачи данных в каждый этап
  const [branches, setBranches] = useState([]); // филиалы с сервера
  const [servicesReception, setServicesReception] = useState([]); // Услуги с сервера
  const [doctorReception, setDoctorReception] = useState([]); // Список доступынх врачей

  // id выбранного филиала для сраванения с типом услуги в Reception services
  const [idType, setIdType] = useState('');

  const [messageActive, setMessageActive] = useState(false);

  // Переключение табов на пк версии
  function selectTab(tab) {

    if (typeof tab === "string") {
      setCurrentTab(tab);
    } else {
      setCurrentTab(tab.currentTarget.id);
    }

    if (tab.currentTarget.id == "branch" & document.location.search == '') {
      setData(prevState => ({
        ...prevState,
        'userId': '',
        'serviceId': '',
      }));
    }

    if (tab.currentTarget.id == "selectType" & document.location.search == '') {
      setData(prevState => ({
        ...prevState,
        'userId': '',
        'serviceId': ''
      }));
    }

    if (tab.currentTarget.id == "doctor" & document.location.search == '' && !config.selectService && branches.length == 1) {
      setData(prevState => ({
        ...prevState,
        'userId': '',
        'tillDate': '',
        'fromDate': '',
      }));

      getAllDoctors(data);
    }
  }

  useEffect(() => {
  }, [data])


  // Переключение табов на мобильной версии

  // переменная с названиями блоков
  const allTabs = ['branch', 'selectType', 'services', 'doctor', 'success', 'finish'];

  if (branches.length == 1) {
    allTabs.splice(0, 1);
  }

  // Функция переключения на шаг вперед
  function NextClick() {
    let key = allTabs.indexOf(currentTab);
    setCurrentTab(allTabs[key + 1]);
  }

  // Функция переключения на шаг назад
  function PrevClick() {

    if (currentTab == "services") {
      setServicesReception([]);
      setActiveType(false);
    }

    let key = allTabs.indexOf(currentTab);
    setCurrentTab(allTabs[key - 1]);

    if (currentTab == "doctor" && config.selectService == false) {
      let key = 2;
      setCurrentTab(allTabs[key - 2]);
    }
    if (currentTab == "doctor" && config.selectService == true) {
      let key = 2;
      if(allTabs[0] == 'branch'){
        setCurrentTab(allTabs[1]);
      } else {
        setCurrentTab(allTabs[0]);
      }
    }
    if (currentTab == "services" && config.selectService == true && document.location.search != '') {
      let key = 2;
      setCurrentTab(allTabs[key - 2]);
    }
    if (currentTab == "doctor" && config.selectService == true && document.location.search != '') {
      let key = 3;
      setCurrentTab(allTabs[key - 1]);
    }
    if (currentTab == "doctor" && config.selectService == true && doctorId) {
      let key = allTabs.indexOf('services');
      setCurrentTab(allTabs[key]);
    }
  }


  // Запрос для получения доступных типов услуг

  function getTypes() {

    web_api.getAppointmentTypes((res) => {

      if (res == 'error') {
        enqueueSnackbar({
          message: 'Не удалось получить типы услуг',
          options: {
            countdown: 5000,
            dismissible: true,
            variant: 'error'
          }
        });
      } else {

        if (res.length == 1) {
          let params = data;
          params.typeServiceText = res[0].value;
        }

        setTypeService(res);
        setData(prevState => ({
          ...prevState,
          res
        }))
      }

    });

  }

  useEffect(() => {
    getTypes()
  }, []);

  // РАБОТА С ФИЛИАЛАМИ

  // Переменная, куда заносятся данные, если филиал всего 1. 
  // При изменении данной константы отрабатывает Use Effect с запросом к API

  const [oneBranch, setOneBranch] = useState(data)
  const adressLocation = window.location;
  const [branchId, setBranchId] = useState('');


  useEffect(() => {

    setData(prevState => ({
      ...prevState,
      'userId': doctorId,
    }))


    if(serviceId != null && linkBranchId != null){
      
      let params = data;
      setTypeReception('select-service');

      web_api.getClinicBranches((res) => {

        if (res == 'error') {
          enqueueSnackbar({
            message: 'Не удалось получить филиалы',
            options: {
              countdown: 5000,
              dismissible: true,
              variant: 'error'
            }
          });
          return false
        } else {
          let branch =  res.find((br) => br.id == linkBranchId);
          
          setBranches(res);

          params.clinicBranchId = branch.id;
          params.name = branch.name;
          params.adress = branch.addressLocation;

          // Добаляем в переменную данные единственного филиала
          setOneBranch(params);  // Дальше запрос в API производится в отдельном UseEffect

          setBranchId(params.clinicBranchId)

          params.id = serviceId;
          params.fromDate = start_period.start_date.format('YYYY-MM-DD');
          params.tillDate = start_period.end_date.format('YYYY-MM-DD');
          params.clinicBranchId = linkBranchId;
          params.type = 0;
          
          setLoader(false);

          setData(prevState => ({
            ...prevState, ...params,
            'isOneBranch': true
          }));

          //переделать, когда сделают эндпойнт для запроса одной услуги по id
          web_api.getOnlineService(params, function (res) {

            if (res == 'error') {
              enqueueSnackbar({
                message: 'Не удалось получить услугу',
                options: {
                  countdown: 5000,
                  dismissible: true,
                  variant: 'error'
                }
              });
      
              return false;
      
            } else {
              const service = res;
              params.serviceText = service.name;
              params.price = service.price;

              setData((prevState) => ({
                ...prevState,
                ...params
              }))
              setCurrentTab('doctor');
              // Вызов функции запроса свободных врачей
              getDoctorsFreeTime(params);
              setdoctorFreeTime(true);      
            }
          });        
          
        };
      });      
    }
    else if(doctorId != null && linkBranchId != null){
      
      let params = data;
      setTypeReception('select-doctor');

      web_api.getClinicBranches((res) => {

        if (res == 'error') {
          enqueueSnackbar({
            message: 'Не удалось получить филиалы',
            options: {
              countdown: 5000,
              dismissible: true,
              variant: 'error'
            }
          });
          return false
        } else {

          let branch =  res.find((br) => br.id == linkBranchId);
          
          setBranches(res);

          params.clinicBranchId = branch.id;
          params.name = branch.name;
          params.adress = branch.addressLocation;

          // Добаляем в переменную данные единственного филиала
          setOneBranch(params);  // Дальше запрос в API производится в отдельном UseEffect

          setBranchId(params.clinicBranchId)

          params.fromDate = start_period.start_date.format('YYYY-MM-DD');
          params.tillDate = start_period.end_date.format('YYYY-MM-DD');
          params.clinicBranchId = linkBranchId;
          
          setLoader(false);

          setData(prevState => ({
            ...prevState, ...params,
            'isOneBranch': true
          }));


          // params.serviceType = 0;

          web_api.getAppointmentTypes((res) => {

            if (res == 'error') {
              enqueueSnackbar({
                message: 'Не удалось получить типы услуг',
                options: {
                  countdown: 5000,
                  dismissible: true,
                  variant: 'error'
                }
              });
            } else {

              params.typeServiceText = res[0].value;
              // Добавляем id филиала
              setIdType(res[0].key);
            }

          });

          // Добаляем в переменную данные единственного филиала
          setOneBranch(params);  // Дальше запрос в API производится в отдельном UseEffect

          setBranchId(params.clinicBranchId)

          setData(prevState => ({
            ...prevState,
            'clinicBranchId': params.clinicBranchId,
            'name': params.name,
            'isOneBranch': true
          }));

          params.fromDate = start_period.start_date.format('YYYY-MM-DD');
          params.tillDate = start_period.end_date.format('YYYY-MM-DD');
          params.userId = doctorId;

          SelectTypeRecep('select-service');

          if (config.selectService == false) {

            // Вызов функции запроса свободных врачей

            setTypeReception('select-doctor')
            getDoctorsFreeTime(params);
            setdoctorFreeTime(true);

          }

          if (config.selectService == true) {
            GetServices(params);
          }
        };
      });      
    }
    // Получаем филиалы доктора, если есть ссылка на определенного доктора
    else if (doctorId != null) {
      // Получаем количество филиалов доктора
      const doctorData = { "userId": doctorId };
      web_api.getClinicBranchesByDoctor(doctorData, function (res) {

        if (res == 'error') {
          enqueueSnackbar({
            message: 'Ошибка 1',
            options: {
              countdown: 5000,
              dismissible: true,
              variant: 'error'
            }
          });
          return false;
        } else {

          setLoader(false);
          let params = data;

          // Убираем выбор филиала, если филиал 1
          if (res.length == 1) {

            setTypeReception('select-doctor');

            setBranches(res);

            params.clinicBranchId = res[0].id;

            // params.serviceType = 0;
            params.name = res[0].name;
            params.adress = res[0].addressLocation;

            web_api.getAppointmentTypes((res) => {

              if (res == 'error') {
                enqueueSnackbar({
                  message: 'Не удалось получить типы услуг',
                  options: {
                    countdown: 5000,
                    dismissible: true,
                    variant: 'error'
                  }
                });
              } else {

                params.typeServiceText = res[0].value;
                // Добавляем id филиала
                setIdType(res[0].key);
              }

            });

            // Добаляем в переменную данные единственного филиала
            setOneBranch(params);  // Дальше запрос в API производится в отдельном UseEffect

            setBranchId(params.clinicBranchId)

            setData(prevState => ({
              ...prevState,
              'clinicBranchId': params.clinicBranchId,
              'name': params.name,
              'isOneBranch': true
            }));

            params.fromDate = start_period.start_date.format('YYYY-MM-DD');
            params.tillDate = start_period.end_date.format('YYYY-MM-DD');
            params.userId = doctorId;

            SelectTypeRecep('select-service');


            if (config.selectService == false) {

              // Вызов функции запроса свободных врачей

              setTypeReception('select-doctor');
              getDoctorsFreeTime(params);
              setdoctorFreeTime(true);

            }

            if (config.selectService == true) {
              GetServices(params);
            }

          } else {
            setBranches(res);
          }
        }
      })
    }
    // Получаем все филиалы, если ссылка на доктора отсутсвует
    else {
      web_api.getClinicBranches((res) => {

        if (res == 'error') {
          enqueueSnackbar({
            message: 'Не удалось получить филиалы',
            options: {
              countdown: 5000,
              dismissible: true,
              variant: 'error'
            }
          });
          return false
        } else {
          setLoader(false);


          // Убираем выбор филиала, если филиал 1
          if (res.length == 1) {
            
            setBranches(res);
            let params = data;

            params.clinicBranchId = res[0].id;
            // params.serviceType = 0;
            params.name = res[0].name;
            params.adress = res[0].addressLocation;

            // Добавляем тип записи на услугу
            setIdType(0);

            // Добаляем в переменную данные единственного филиала
            setOneBranch(params);  // Дальше запрос в API производится в отдельном UseEffect

            setBranchId(params.clinicBranchId)

            setData({...params, 'isOneBranch': true})

            if (config.selectService == false) {
              getAllDoctors(params);
            }

            if (config.selectService == true) {
              setCurrentTab('selectType')
            }

          } else {
            setBranches(res);
          }
        };
      });
    }

  }, [adressLocation])

  // Функция выбора филиала
  function SelectBranch(event) {

    let params = data;
    let branch_id;

    // Добавляем id филиала
    setIdType(event.target.id);

    // Запрос на получение докторов по id филиала, если выбор услуги отключен
    if (config.selectService == false) {

      if (!event.currentTarget.id) branch_id = event.target.id; else branch_id = event.currentTarget.id;
      params.clinicBranchId = branch_id;
      setBranchId(branch_id)
      // params.serviceType = typeService[0].key;
      params.fromDate = start_period.start_date.format('YYYY-MM-DD');
      params.tillDate = start_period.end_date.format('YYYY-MM-DD');
      params.name = event.currentTarget.dataset.name;
      params.adress = event.currentTarget.dataset.adress;

      getDoctorsFreeTime(params);
      setCurrentTab('doctor');


    } else {

      if (!event.currentTarget.id) branch_id = event.target.id; else branch_id = event.currentTarget.id;
      params.clinicBranchId = branch_id;
      // params.serviceType = typeService[0].key;
      params.name = event.currentTarget.dataset.name;
      params.adress = event.currentTarget.dataset.adress;
      params.typeServiceText = typeService[0].value;
      params.fromDate = start_period.start_date.format('YYYY-MM-DD');
      params.tillDate = start_period.end_date.format('YYYY-MM-DD');

      if (document.location.search == '') {
        setData(params)
        setBranchId(branch_id)
        setCurrentTab('selectType')
      }

      // Переходим на выбор услуги, если есть ссылка на врача в адресной строке
      if (document.location.search != '') {
        setTypeReception('select-doctor')
        setData(params);
        setBranchId(params.clinicBranchId);
        GetServices(params);
      }

    }

    let session = new Session;

    if (session.getSession('MessageSwipe')) {
      setMessageActive(false);
    }
    else {
      setMessageActive(true);
      session.createSession('MessageSwipe', true, 1000 * 60 * 60 * 24 * 30);
    }

  }

  function GetServices(params, typeService = 'Платный приём') {
    // Запрос на получение услуги по id филиалу и типу услуги 
    web_api.getOnlineServices(params, function (res) {

      if (res == 'error') {
        enqueueSnackbar({
          message: 'Не удалось получить услуги',
          options: {
            countdown: 5000,
            dismissible: true,
            variant: 'error'
          }
        });

        return false;

      } else {

        if (!params.typeServiceText) { params.typeServiceText = typeService; }


        // Автовыбор услуги если она одна
        // if (res.length == 1) {

        //   params.serviceId = res[0].id;
        //   params.fromDate = start_period.start_date.format('YYYY-MM-DD');
        //   params.tillDate = start_period.end_date.format('YYYY-MM-DD');
        //   params.serviceText = res[0].name;

        //   getDoctorsFreeTime(params);
        // }

        setReceptionLoader(false);
        setData(params);
        setServicesReception(res);
        setActiveType(true);
        setCurrentTab('services');
      }

    });
  }

  const [doctorFreeTime, setdoctorFreeTime] = useState(true);

  function getAllDoctors(params) {
    // params.serviceType = 0;
    setIdType('');
    web_api.getDoctors(params, function (res) {

      if (res == 'error') {
        enqueueSnackbar({
          message: 'Не удалось получить врачей',
          options: {
            countdown: 5000,
            dismissible: true,
            variant: 'error'
          }
        });

        return false;

      } else {

        // Переходим на выбор времени врача, если врач 1
        if (res.length == 1) {
          let params_doctor = params;

          params_doctor.fromDate = start_period.start_date.format('YYYY-MM-DD');
          params_doctor.tillDate = start_period.end_date.format('YYYY-MM-DD');
          params_doctor.serviceText = res[0].specializations[0].name;
          params_doctor.userId = res[0].id;

          setData(prevState => ({
            ...prevState,
            'userId': res[0].id,
          }));
      
          if (config.selectService == true) {
            GetServices(params_doctor);
            setNextTabType("services");
          }
      
          if (config.selectService == false) {
            getDoctorsFreeTime(params_doctor, () => { setdoctorFreeTime(true) });
          }


        } else {
          setdoctorFreeTime(false);
          setDoctorReception(res);
          setCurrentTab('doctor');
        }
      }
    });
  }

  const [typeReception, setTypeReception] = useState(false);
  const [nextTabType, setNextTabType] = useState('doctor');

  // ВЫБОР ТИПА ЗАПИСИ НА ПРИЕМ (ПО ВРАЧУ ИЛИ ПО УСЛУГИ)
  function SelectTypeRecep(id) {
    setTypeReception(id)

    if (id == 'select-service') {
      GetServices(data)
      setNextTabType("services")
    }
    if (id == 'select-doctor') {
      getAllDoctors(data)
      setNextTabType("doctor")
    }

  }

  function selectDoctor(event) {
    let params = data;
    params.userId = event.currentTarget.id;
    params.fromDate = start_period.start_date.format('YYYY-MM-DD');
    params.tillDate = start_period.end_date.format('YYYY-MM-DD');

    if (config.selectService == true) {
      GetServices(params);
    }

    if (config.selectService == false) {
      getDoctorsFreeTime(params, () => { setdoctorFreeTime(true) });
    }
  }


  // РАБОТА С УСЛУГАМИ

  // Функция выбора типа услуги
  function SelectType(event) {

    let params = data;

    params.serviceType = parseInt(event.target.dataset['id']);
    params.typeServiceText = event.target.innerText;

    // Добавляем id типа услуги
    setIdType(params.serviceType);

    // Запрос на получение услуг по id филиалу и типу услуги
    web_api.getOnlineServices(params, function (res) {

      if (res == 'error') {
        enqueueSnackbar({
          message: 'Не удалось получить услуги',
          options: {
            countdown: 5000,
            dismissible: true,
            variant: 'error'
          }
        });

        return false;

      } else {

        //Считаю ненужным, так как пациенту возможно не нужна эта услуга
        // Автовыбор услуги если она одна
        // if (res.length == 1) {

        //   params.serviceId = res[0].id;
        //   params.fromDate = start_period.start_date.format('YYYY-MM-DD');
        //   params.tillDate = start_period.end_date.format('YYYY-MM-DD');
        //   params.serviceText = res[0].name;

        //   getDoctorsFreeTime(params);
        // }

        setReceptionLoader(false);
        setData(params);
        setServicesReception(res);
        setActiveType(true);
        setCurrentTab('services')
      }

    });

  }

  // Переменная с текущей неделей
  let [start_period, setStartPeriod] = useState({
    start_date: moment().startOf("isoWeek"),
    end_date: moment().endOf("isoWeek")
  });

  // Функция выбора услуги
  function SelectService(serviceId, serviceName, servicePrice, serviceDescription) {

    let params = data;

    if(config.show_services_price && servicePrice){
      params.price = servicePrice;
    }

    params.serviceId = serviceId;
    params.fromDate = start_period.start_date.format('YYYY-MM-DD');
    params.tillDate = start_period.end_date.format('YYYY-MM-DD');
    params.serviceText = serviceName;
    params.serviceDescription = serviceDescription;

    // Вызов функции запроса свободных врачей
    getDoctorsFreeTime(params);
    setdoctorFreeTime(true);
  }


  // РАБОТА С ДОКТОРАМИ

  // Функция запроса свободных врачей по id услуги
  function getDoctorsFreeTime(params, callback = () => { }) {

    setReceptionLoader(true);

    web_api.getDoctorsFreeTime(params, function (res) {
      setReceptionLoader(false);

      if (res == 'error') {
        enqueueSnackbar({
          message: 'Не удалось получить врачей',
          options: {
            countdown: 5000,
            dismissible: true,
            variant: 'error'
          }
        });

        return false;

      } else {

        // Добавление к запросу массива свободного времени врачей
        let new_res = res.map(doctor => {

          doctor.arrFreeTimes = {};
          doctor.freeWindowDay = {};

          doctor.freeTimes.map(time => {
            if (!doctor.arrFreeTimes[time.date]) doctor.arrFreeTimes[time.date] = [];

            doctor.arrFreeTimes[time.date].push(time);

            if (doctor.freeWindowDay[time.date] == undefined) doctor.freeWindowDay[time.date] = 0;

            if (time.isFreeDocTime) {
              let counter = doctor.freeWindowDay[time.date];
              doctor.freeWindowDay[time.date] = counter + 1;
            }
          });
          return doctor;
        });


        setDoctorReception(new_res);
        setCurrentTab('doctor');

        callback();
      }
    });
  }


  // Функция закрытия окна уведомления о свайпах на мобилке
  function closeMessageSwipe() {
    setMessageActive(false);
  }

  // Функции переключения месяц и недель на блоке с докторами

  // След. неделя
  function clickNextWeek(period) {

    let params = data;

    params.fromDate = period.start_date.format('YYYY-MM-DD');
    params.tillDate = period.end_date.format('YYYY-MM-DD');

    setStartPeriod(period);
    getDoctorsFreeTime(params);
  }

  // Пред. неделя
  function clickPrevWeek(period) {

    let params = data;

    params.fromDate = period.start_date.format('YYYY-MM-DD');
    params.tillDate = period.end_date.format('YYYY-MM-DD');

    setStartPeriod(period);
    getDoctorsFreeTime(params);
  }

  // След. месяц
  function clickNextMounth(period) {

    let params = data;

    params.fromDate = period.start_date.format('YYYY-MM-DD');
    params.tillDate = period.end_date.format('YYYY-MM-DD');

    setStartPeriod(period);
    getDoctorsFreeTime(params);
  }

  // След. месяц
  function clickPrevMounth(period) {

    let params = data;

    params.fromDate = period.start_date.format('YYYY-MM-DD');
    params.tillDate = period.end_date.format('YYYY-MM-DD');

    setStartPeriod(period);
    getDoctorsFreeTime(params);
  }

  // Функция выбора доктора
  function SelectDoctorFreeTime(doctor, time, setDoctorsModalProps) {

    let date = new Date(time.date);
    date =
      ('0' + (date.getDate())).slice(-2) + '.' +
      ('0' + (date.getMonth() + 1)).slice(-2) + '.' +
      date.getFullYear();

    setData(prevState => ({
      ...prevState,
      'doctorSpecializationId': doctor.id,
      "timeBegin": time.timeBegin,
      "timeEnd": time.timeEnd,
      "cabinetId": time.cabinetId,
      'date': time.date,
      'dateText': date,
      "doctorText": doctor.userName,
    }));

    document.querySelector('body').style.overflow = '';
    if(setDoctorsModalProps) setDoctorsModalProps(state => { state.active = false });
    setCurrentTab('success');
  }


  // РАБОТА С ОТПРАВКОЙ ДАННЫХ

  // Функция проверки данных комментария (Если написан текст, то отправляется в data)
  function getComment(event) {

    if (event.target.name == 'name') {
      setData(prevState => ({
        ...prevState,
        'leadName': event.target.value
      }));
    }

    if (event.target.name == 'phone') {
      setData(prevState => ({
        ...prevState,
        'leadPhone': event.target.value
      }));
    }

    if (event.target.name == 'email') {
      setData(prevState => ({
        ...prevState,
        'leadEmail': event.target.value
      }));
    }

    if (event.target.name == 'comment') {
      setData(prevState => ({
        ...prevState,
        'Comment': event.target.value
      }));
    }

    if (event.target.name == 'complaints') {
      setData(prevState => ({
        ...prevState,
        'complaints': event.target.value
      }));
    }
  }

  // Функция провреки и отправки данных на сервер
  function Success(event) {


    if (branches.length == 1 && !data.typeServiceText) {
      setData(prevState => ({
        ...prevState,
        'typeServiceText': typeService[0].value
      }));
    }

    let req = {...data};

    if(req['complaints']){
      req['Comment'] = req['Comment'] + '\nЧто беспокоит: ' + req['complaints'];
    }

    if (data.leadName && data.leadPhone) {
      setReceptionLoader(true);

      web_api.sendPostSchedule(req, function (res) {
        setReceptionLoader(false);

        if (res == 'error') {
          enqueueSnackbar({
            message: 'Не удалось завершить запись',
            options: {
              countdown: 5000,
              dismissible: true,
              variant: 'error'
            }
          });
          return false;
        } else {
          setCurrentTab('finish');
        }
      });
    } else {

      let messageError;


      if (!data.leadName && !data.leadPhone) {
        messageError = 'Введите ваше имя и номер телефона ';
      } else if (!data.leadName) {
        messageError = 'Введите ваше имя';
      } else if (!data.leadPhone) {
        messageError = 'Введите ваш номер телефона';
      }

      enqueueSnackbar({
        message: messageError,
        options: {
          countdown: 5000,
          dismissible: true,
          variant: 'error'
        }
      });

    }
  }

  return (<>
    {loader &&
      <TopLoader show cowerlor="var(--primary)" thickness="4px" />
    }
    <div className="reception" style={loader ? { opacity: '0.75', filter: 'blur(3px)', pointerEvents: 'none' } : { opacity: '1' }}>
      <HeaderMobile title="Запись на прием" type="reception" />
      {/* <Header title="Запись на прием" type="reception" /> */}
      <ReceptionBlock data={data} nextTabType={nextTabType} typeReception={typeReception} currentTab={currentTab} NextClick={NextClick} PrevClick={PrevClick} activeDone={currentTab == 'finish' ? true : false} branchesCount={branches.length} onClick={(event) => { selectTab(event) }}>
        {!loader && <div style={receptionLoader ? { opacity: '0.75', filter: 'blur(3px)', pointerEvents: 'none', transition: 'all 0.5s ease' } : { opacity: '1', transition: 'all 0.5s ease' }}>
          {currentTab == 'branch' &&
            <><ReceptionBranch data={branches} onClick={event => { SelectBranch(event) }} /> </>
          }
          {currentTab == 'selectType' && config.selectService == true &&
            <SelectTypeReception onClickg={event => { SelectTypeRecep(event.currentTarget.id) }} />
          }
          {currentTab == 'services' && config.selectService == true &&
            <ReceptionServices
              data={servicesReception}
              typeService={typeService}
              onClick={SelectService}
              onClickType={event => SelectType(event)}
              activeType={activeType}
              idType={idType}
            />
          }
          {currentTab == 'doctor' &&
            <ReceptionDoctor
              data={doctorReception}
              start_period={start_period}
              clickNextWeek={clickNextWeek}
              clickPrevWeek={clickPrevWeek}
              clickNextMounth={clickNextMounth}
              clickPrevMounth={clickPrevMounth}
              onClick={SelectDoctorFreeTime}
              closeMessageSwipe={closeMessageSwipe}
              messageActive={messageActive}
              active={true}
              branchId={branchId}
              doctorFreeTime={doctorFreeTime}
              onClickselectDoctor={selectDoctor}
              typeReception={typeReception}
              receptionData={data}
            />
          }
          {currentTab == 'success' &&
            <ReceptionSuccess data={data} onClick={event => { Success(event) }} onChange={event => { getComment(event) }} />
          }
          {currentTab == 'finish' &&
            <ReceptionFinish data={data} />
          }
        </div>}
      </ReceptionBlock>
    </div>
  </>)

}

export default Reception;