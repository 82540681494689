import React, {useEffect, useRef, useState} from 'react';
import './miniModal.css';
import Buttons from '../buttons/buttons';

const MiniModal = ({ isVisible = false, title, content, onClose, imageSrc }) => {
    const keydownHandler = ({ key }) => {
      switch (key) {
        case 'Escape':
          onClose();
          break;
        default:
      }
    };

    const blockRef = useRef(null);
    const [isSwipeVisible, setSwipeVisible] = useState(false);
    const [styleContainer, setStyleContainer] = useState(null);

  
    useEffect(() => {
      document.addEventListener('keydown', keydownHandler);
      return () => document.removeEventListener('keydown', keydownHandler);
    });

    useEffect(() => {
      if (blockRef.current) {
        const blockHeight = blockRef.current.offsetHeight;
        (imageSrc && blockHeight > 200) ? setSwipeVisible(true) : setSwipeVisible(false);
        !imageSrc ? setStyleContainer({ height: 'auto'}) : setStyleContainer(null);
      }
    }, [isVisible]);
  
    return !isVisible ? null : (
      <div className="modal" onClick={onClose}>
        
        <div className="modal-container"  style={styleContainer} onClick={e => e.stopPropagation()}>
            {imageSrc 
                ? <img className='modal-image' src={imageSrc}/>     
                : ''
            }
            <div className="modal-dialog"  ref={blockRef} >
              
              <div className="modal-header">
                  <h3 className="modal-title">{title}</h3>
                  <div className="modal-close-btn"><Buttons type="button-close-mini" icon={"icon icon_s icon_button-close icon_background-auto"} onClick={onClose}/></div>
              </div>
              <div className="modal-body">
                  <div className="modal-content">{content}</div>
              </div>
            </div>
            {isSwipeVisible
              ?  <div className='modal-icon-swipe'>
                  <span className="icon icon_m icon_background-auto icon_swipeDown"></span>
                </div>
              : <></>
            }
        </div>
        <div className="modal-bottom"></div>
      </div>
    );
  };

  export default MiniModal;