import React, { useState, useEffect } from 'react';
import Inputs from '../forms/inputs';
import './Search.css';

function Search({ data, field, callback }) {


  const getJobs = (words) => {

    words = words.filter((word) => word != "");
    words = words.map((word) => word.replace(/[^A-Za-zА-Яа-я0-9_]/g, "", ""));
    if (!words.length) return false;

    let string = words.join(".*)(");
    let mask = new RegExp("(.*" + string + ")", "i");

    return data.filter((s) => {
      return mask.test(s[field]);
    });

  };

  function search_array(value) {
    let search = value.split(" ");
    let filter_array = getJobs(search);
    callback(filter_array);
  }

  return (
    <div className="search">
      <div className="search__container">
        <div className="search__icon">
          <span className="icon icon_xs icon_background-auto icon_search"></span>
        </div>
        <input onChange={(event) => search_array(event.target.value)} placeholder="Поиск..." />
      </div>
    </div>
  );

}

export default Search;