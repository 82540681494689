import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import './App.css'
import './reset.css'
import './App.css'
import Context from './context.js'
import Session from './helpers/Session.js';
import Reception from './reception/Reception.jsx';
import ListLinkDoctors from './reception/listLinkDoctors.jsx';
import WebApi from './helpers/WebApi';
import { useSnackbar } from './notification/index';
import Preloader from './components/Preloader';
import ListLinkServices from './reception/listLinkServices';

const UpdateToken = ({ enqueueSnackbar, config, session, setMore }) => {

  let data = {
    "name": config.name,
    "password": config.password
  }

  let web_api = new WebApi;
  web_api.auth(data, feedback);

  // Функци исполняемая после ответа от сервера
  function feedback(response) {

    if (response == 'error') {

      enqueueSnackbar({
        message: 'Не удалось авторизироваться',
        options: {
          countdown: 5000,
          dismissible: true,
          variant: 'error'
        }
      });

      return false;

    }

    let session = new Session;
    session.createSession('token', response.data);
    setMore(true);

  }

}

function App() {

  const { enqueueSnackbar } = useSnackbar();

  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') window.config = require('./config.json');

  let config = window.config;
  let session = new Session;

  const [global, setGlobal] = useState({});
  const [more, setMore] = useState(false);

  useEffect(() => {

    if (!more) {
      let web_api = new WebApi;

      web_api.getAppointmentTypes((res) => {
        if (res != 'error') {
          setMore(true)
        } else {
          UpdateToken({
            enqueueSnackbar,
            config,
            session,
            setMore
          })
        }
      });
    }

  }, [more])


  if (more == false) { return <Preloader /> }

  return <>
    <style>{`
        :root {
          --default: `+ config.color_default + `;
          --neutral: #ebebeb;
          --text: `+ config.color_default_text + `;
          --text_inverse: `+ config.color_primary_text + `;
          --text_color: red;
          --primary: `+ config.color_primary + `;
          --finish_text_bg_color: `+ config.finish_text_bg_color + `;
          --success: #29d27e;
          --warning: #f9be1b;
          --danger: #ff6d6d;
          --gray: #707070;
          --icons_primary_inverse: `+ config.icons_primary_inverse + `;
          --icons_default_inverse: `+ config.icons_default_inverse + `;
        }
      `}</style>


    <BrowserRouter basename={(config.batch) ? config.batch : '/'}>
      <Context.Provider value={[global, setGlobal]}>
        <div className="main">
          <div className="content">
            <Switch>
              <Route exact path={'/'} component={Reception} />
              <Route exact path={'/' + config.doctor_link} component={ListLinkDoctors} />
              <Route exact path={'/' + config.service_link} component={ListLinkServices} />
            </Switch>
          </div>
        </div>
      </Context.Provider>
    </BrowserRouter>
  </>


}

export default App;
