import React from "react";
import './buttons.css';
import '../icons/icons.css';
import { NavLink } from 'react-router-dom';

function Buttons({ type, text, subtext, className, icon, onClick, id, to, typeButton, disabled = false, style, dataAdress, dataName}) {

    let buttons_class = {
        "button": {
            'main': 'button-default',
            'container': 'button-default__container',
            'icon': 'button-default__icon',
            'text': 'button-default__text',
            'active': '',
        },
        "button-primary": {
            'main': 'button-primary',
            'container': 'button-primary__container',
            'icon': 'button-primary__icon',
            'text': 'button-primary__text',
            'active': 'button-primary_active',
        },
        'link': {
            'main': 'button-link',
            'container': 'button-link__container',
            'icon': 'button-link__icon',
            'text': 'button-link__text',
            'active': '',
        },
        'link-icon': {
            'main': 'button-link button-link_icon',
            'container': 'button-link__container',
            'icon': 'button-link__icon',
            'text': 'button-link__text',
            'active': '',
        },
        'button-square': {
            'main': 'button-square',
            'container': 'button-square__container',
            'icon': 'button-square__icon',
            'text': 'button-square__text',
            'active': '',
        },
        'button-close-mini': {
            'main': 'button-close-mini',
            'container': 'button-close__container',
            'icon': 'button-close__icon',
            'text': 'button-close__text',
            'active': '',
        },
        'button-close': {
            'main': 'button-close',
            'container': 'button-close__container',
            'icon': 'button-close__icon',
            'text': 'button-close__text',
            'active': '',
        },
        'button-branch': {
            'main': 'button-branch',
            'container': 'button-branch__container',
            'icon': 'button-branch__icon',
            'text': 'button-branch__text',
            'active': '',
        },
        'button-time-stamp': {
            'main': 'button-time-stamp',
            'container': 'button-time-stamp__container',
            'icon': 'button-time-stamp__icon',
            'text': 'button-time-stamp__text',
            'active': '',
        }
    }

    if (!className)  className = '';
    if (!text) text = '';
    if (!id) id = '';
    if (!to) to = false;
    if (!type || buttons_class[type] == undefined) type = 'button';

    let class_array = buttons_class[type];

    return (
        <div className="button-block" style={style}>
            {!to ? (
                    <button data-adress={dataAdress} data-name={dataName} type={typeButton} disabled={disabled} style={disabled ? {pointerEvents: 'none'} : {}} onMouseOut={(event) => {event.target.blur()}} id={id} className={class_array.main + " " + (className)} onClick={onClick}>
                        <div className={class_array.container}>
                            <div className={class_array.icon}>
                                <span className={icon}></span>
                            </div>
                            <div className={class_array.text}>
                                {text}
                                {subtext && <span>{subtext}</span> }
                            </div>
                        </div>
                    </button> 
            ) : (
                <NavLink id={id} className={class_array.main + " " + (className)} onClick={onClick} to={to}>
                    <div className={class_array.container}>
                        <div className={class_array.icon}>
                            <span className={icon}></span>
                        </div>
                        <div className={class_array.text}>
                            {text}
                        </div>
                        <div className={class_array.active}></div>
                    </div>
                </NavLink> 
            )}
        </div>
    );
}

export default Buttons;