import React, { useState } from 'react';
import './reception-block.css';
import Buttons from '../components/buttons/buttons';
import Inputs from '../components/forms/inputs';

import InputMask from 'react-input-mask';


function ReceptionSuccess({ active, onClick, data, onChange }) {

  let config = window.config;

  return (
    <div className={"reception-date"}>
      <div className="reception-date__container">
        <div className="reception-date__content">
          <div className="reception-date__title">Запись на приём</div>
          <div className="reception-date__time">{data.dateText} на {data.timeBegin != false ? (data.timeBegin.slice(0, -3) + " - " + data.timeEnd.slice(0, -3)) : "33"}</div>
          <div className="reception-date__info">
            <div><span>Филиал: </span>{data.name}</div>
            {data.adress != '' && data.adress != null && data.adress != undefined &&
              <div><span>Адрес: </span>{data.adress}</div>
            }
            <div><span>Врач: </span>{data.doctorText}</div>
            {config.selectService == true && data.serviceText &&
              <div><span>Услуга: </span>{data.serviceText}</div>
            }
            {data.serviceType == 0 && (config.selectService == true) && (config.show_services_price == true) && data.price &&
              <div><span>Цена услуги: </span>{data.price.toLocaleString()} {config.currency}</div>
            }
            {config.selectService == true && data.typeServiceText &&
              <div><span>Тип услуги: </span>{data.typeServiceText}</div>
            }
            {config.selectService == true && data.serviceDescription &&
              <div><span>Об услуге: </span>{data.serviceDescription}</div>
            }
          </div>
          <hr/>
          <div className="reception-date__info-policy">Нажимая кнопку <b>"Подтвердить запись"</b> я даю свое согласие на обработку моих персональных данных, определенных в <a href={config.link_policy} target="_bank">"Согласии на обработку персональных данных"</a>. </div>
          <hr/>
        </div>
        <div className="reception-date__form">

          <Inputs placeholder={'Ваше ФИО *'} name={'name'} onChange={onChange} type={'text'} className={`reception-date__input ${!data.leadName && 'error'}`} />
          <div className={`reception-date__input ${data?.leadPhone?.length != 12 && 'error'}`}><InputMask mask="+79999999999" maskChar="" name={'phone'} type={'text'} placeholder={'Ваш телефон *'} onChange={onChange}></InputMask></div>
          <Inputs placeholder={'Ваш Email (необязательно)'} name={'email'} onChange={onChange} type={'text'} className="reception-date__input" />
          <textarea name="comment" id="" placeholder="Комментарий (необязательно)" onChange={onChange} className="reception-date__input"></textarea>
          <textarea name="complaints" id="" placeholder="Что беспокоит? (необязательно)" onChange={onChange} className="reception-date__input" style={{marginTop: "16px"}}></textarea>

          <div className="reception-date__button-done">
            <Buttons disabled={!data.leadName || data.leadPhone?.length != 12} type="button-primary" text="Подтвердить запись" icon="icon icon_s icon_background-auto icon_reception-done" onClick={onClick} />
          </div>

        </div>

      </div>

    </div>
  )

}

export default ReceptionSuccess;