import axios from 'axios';
import Session from '../helpers/Session';

export default class WebApi {

    constructor() {
        let session = new Session;
        this.token = session.getSession('token');
        this.protocol = window.location.protocol;
        this.config = window.config;
    }

    // POST запросы

    // Запрос на авторизацию

    auth(data, feedback) {

        return axios({
            method: 'POST',
            url: this.protocol + '//' + this.config.api + '/OnlineAppointment/Auth',
            timeout: 5000,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Cache-Control': 'no-cache',
            },
            data: JSON.stringify(data),
        })
            .then(function (response) {
                console.log(response)
                feedback(response);
            })
            .catch(function (error) {
                console.log(error)
                feedback('error');
            });
    }

    // Запрос на запись на прием

    sendPostSchedule(data, PostSchedule) {
        console.log(data);
        let token = this.token;
        axios({
            method: 'POST',
            url: this.protocol + '//' + this.config.api + '/OnlineAppointment/v3/PostSchedule',
            headers: {
                'Content-Type': 'text/json',
                'Authorization': 'Bearer ' + token
            },
            data: JSON.stringify(data),
        })
            .then(function (response) {
                PostSchedule(true);
            })
            .catch(function (error) {
                let session = new Session;
                // session.createSession('token', undefined);
                PostSchedule('error');
                // //window.location.reload();
            });

    }


    // Получение списка типов приёма

    getAppointmentTypes(AppointmentTypes) {
        let token = this.token;

        axios({
            method: 'POST',
            url: this.protocol + '//' + this.config.api + '/OnlineAppointment/v3/GetServiceTypes',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                'Authorization': 'Bearer ' + token
            },
        })
            .then(function (response) {
                let json_res = response.request.response;
                let res = JSON.parse(json_res);
                AppointmentTypes(res);
                console.log(response)
            })
            .catch(function (error) {
                let session = new Session;
                session.createSession('token', undefined);
                AppointmentTypes('error');
                console.log(error)
                //window.location.reload();
            });

    }

    // Получение списка филиалов

    getClinicBranches(ClinicBranches) {
        let token = this.token;

        axios({
            method: 'POST',
            url: this.protocol + '//' + this.config.api + '/OnlineAppointment/v3/GetClinicBranches',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                'Authorization': 'Bearer ' + token
            },
        })
            .then(function (response) {
                let json_res = response.request.response;
                let res = JSON.parse(json_res);
                ClinicBranches(res);
            })
            .catch(function (error) {
                let session = new Session;
                session.createSession('token', undefined);
                ClinicBranches('error');
                //window.location.reload();
            });

    }

    // Получение списка доступных онлайн-записей по заданному филиалу и типу записи

    getOnlineServices(data, OnlineServices) {
        let token = this.token;

        axios({
            method: 'POST',
            url: this.protocol + '//' + this.config.api + '/OnlineAppointment/v3/GetServices',
            data: data,
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                'Authorization': 'Bearer ' + token
            },
        })
            .then(function (response) {
                let json_res = response.request.response;
                let res = JSON.parse(json_res);
                OnlineServices(res);
            })
            .catch(function (error) {
                let session = new Session;
                session.createSession('token', undefined);
                OnlineServices('error');
                //window.location.reload();
            });

    }

    //Получение одной услуги {
    //   "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   "type": 0,
    //   "clinicBranchId": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
    // }
    getOnlineService(data, OnlineServices) {
        let token = this.token;

        axios({
            method: 'POST',
            url: this.protocol + '//' + this.config.api + '/Price',
            data: data,
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                'Authorization': 'Bearer ' + token
            },
        })
            .then(function (response) {
                let json_res = response.request.response;
                let res = JSON.parse(json_res);
                OnlineServices(res);
            })
            .catch(function (error) {
                let session = new Session;
                session.createSession('token', undefined);
                OnlineServices('error');
                //window.location.reload();
            });

    }

    getDoctors(data, allDoctors) {
        let token = this.token;

        axios({
            method: 'POST',
            url: this.protocol + '//' + this.config.api + '/OnlineAppointment/v3/GetDoctors',
            data: data,
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
            .then(function (response) {
                let json_res = response.request.response;
                let res = JSON.parse(json_res);
                allDoctors(res);
            })
            .catch(function (error) {
                let session = new Session;
                session.createSession('token', undefined);
                allDoctors('error');
                //window.location.reload();
            });

    }

    // Получение коллекции врачей, у которых есть свободные окна для записи по переданным параметрам

    getDoctorsFreeTime(data, DoctorsFreeTime) {
        let token = this.token;

        axios({
            method: 'POST',
            url: this.protocol + '//' + this.config.api + '/OnlineAppointment/v3/GetDoctorsFreeTime',
            data: data,
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(function (response) {
                let json_res = response.request.response;
                let res = JSON.parse(json_res);
                DoctorsFreeTime(res);
            })
            .catch(function (error) {
                let session = new Session;
                session.createSession('token', undefined);
                DoctorsFreeTime('error');
                //window.location.reload();
            });

    }

    // Получение всех специализаций врачей

    getSpecializationsDoctors(data, SpecializationsDoctors) {
        let token = this.token;

        axios({
            method: 'POST',
            url: this.protocol + '//' + this.config.api + '/OnlineAppointment/v3/GetSpecializations',
            data: data,
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(function (response) {
                let json_res = response.request.response;
                let res = JSON.parse(json_res);
                SpecializationsDoctors(res);
            })
            .catch(function (error) {
                let session = new Session;
                session.createSession('token', undefined);
                SpecializationsDoctors('error');
                //window.location.reload();
            });

    }

    // Получение списка всех врачей

    getAllDoctors(allDoctors) {
        let token = this.token;

        axios({
            method: 'GET',
            url: this.protocol + '//' + this.config.api + '/OnlineAppointment/v3/GetAllDoctors',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(function (response) {

                let json_res = response.request.response;
                let res = JSON.parse(json_res);
                console.log('все окей')
                allDoctors(res)

            })
            .catch(function (error) {
                let session = new Session;
                session.createSession('token', undefined);

                allDoctors('error')
                console.log('все не окей')
                //window.location.reload();
            });
    }

    // Получение всех специализаций врачей

    getClinicBranchesByDoctor(data, ClinicBranchesByDoctor) {
        let token = this.token;

        axios({
            method: 'POST',
            url: this.protocol + '//' + this.config.api + '/OnlineAppointment/v3/GetClinicBranchesByDoctor',
            data: data,
            headers: {
                'Content-Type': 'application/json-patch+json',
                'accept': 'text/plain',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(function (response) {
                let json_res = response.request.response;
                let res = JSON.parse(json_res);
                ClinicBranchesByDoctor(res);
            })
            .catch(function (error) {
                let session = new Session;
                session.createSession('token', undefined);
                ClinicBranchesByDoctor('error');
                //window.location.reload();
            });

    }


}