import React, { useEffect } from "react";

import './forms.css';
import '../icons/icons.css';

function Inputs({ placeholder, className, iconImage, iconSize, iconPosition, modify, Func, type, name, error, errorMessage, value, onChange }) {

    return (
        <div>
            {error == true &&
                <div className="error-message">{errorMessage}</div>
            }
            <div className={className + `${error == true ? " error" : ""}`}>
                {iconImage &&
                    <div className={className + "__icon"}>
                        <span className={"icon " + iconImage + " " + iconSize + " " + iconPosition}></span>
                    </div>
                }
                <input type={type} name={name} placeholder={placeholder} value={value} onClick={Func} onChange={onChange} />
            </div>
        </div>
    );
}

export default Inputs;